import axios from "axios";

if (window.origin.includes("localhost")) {
  axios.defaults.baseURL = "http://localhost:3000";
} else {
  axios.defaults.baseURL = "http://192.168.0.109:3000";
}
// Your API base URL

// Function to get your auth token (adjust as per your storage mechanism)
const getAuthToken = () => {
  return localStorage.getItem("auth-token"); // Example: token stored in localStorage
};

axios.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Now you can use axios normally in your components/services
