import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import SignIn from "./SignIn";
import { AuthProvider } from "./context/AuthProvider";
import { UserProvider } from "./context/UserProvider";
import AuthGate from "./AuthGate";
import { AxiosProvider } from "./context/AxiosProvider";

function App() {
  useEffect(() => {
    window.addEventListener("load", () => {
      document.body.focus();
    });
    document.body.focus();
  }, []);
  return (
    <div className="App">
      <AuthProvider>
        <AxiosProvider>
          <UserProvider>
            <AuthGate />
          </UserProvider>
        </AxiosProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
