import { Box, Link, Stack, Typography } from "@mui/material";
import React from "react";

export default function Footer() {
  return (
    <Stack alignItems={"center"}>
      <Typography>
        Write to us at&nbsp;
        <Link href="mailto:contact@yogeshbhatt.com">
          contact@yogeshbhatt.com
        </Link>
      </Typography>
      <Link href="/privacy policy.html">Privacy Policy</Link>
    </Stack>
  );
}
