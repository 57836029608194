import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "./Footer";
import GoogleBrandButton from "./component/GoogleBrandButton/GoogleBrandButton";

export default function SignIn() {
  function handleSignIn() {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/google`;
  }
  return (
    <Box
      sx={{
        backgroundImage: `url('/home-bg.jpg')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        width: "100%",
        p: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "min(100%, 1300px)" }}>
        <Box
          boxShadow={10}
          sx={{
            p: 1,
            background: "white",
            borderRadius: { xs: "13px", md: "20px" },
            display: "flex",
            justifyContent: "center",
            backgroundAttachment: "fixed",
            alignItems: "center",
            flexDirection: "column",
            gap: { xs: 3, sm: 5 },
          }}
        >
          <Stack
            sx={{
              img: {
                width: { xs: "50px", md: "150px" },
              },
            }}
            gap={1}
            alignItems={"center"}
          >
            <img
              style={{ borderRadius: "10px" }}
              src="/android-chrome-512x512.png"
              alt="app logo"
            />
            <Typography
              color={"purple"}
              textAlign={"center"}
              sx={{
                fontWeight: "bold",
                fontSize: { xs: "2.5rem", md: "3rem" },
              }}
              variant="h3"
            >
              Photo Roulette
            </Typography>
          </Stack>
          <Divider sx={{ my: -1.5 }} flexItem />

          <Typography
            fontSize={{ xs: "1.75rem", md: "2.215rem" }}
            textAlign={"center"}
            variant="h4"
          >
            Rediscover Your Memories - One Photo at a Time
          </Typography>

          {/* <Button
            startIcon={<GoogleIcon />}
            variant="contained"
            onClick={handleSignIn}
          >
            Sign in with google
          </Button> */}
          <GoogleBrandButton onClick={handleSignIn}></GoogleBrandButton>
          <Typography
            textAlign={"center"}
            sx={{ fontSize: { xs: "1.3rem", md: "1.5rem" } }}
            variant="h5"
          >
            Uncover the unseen in your Google Photos collection. Our app fills a
            gap Google Photos doesn't address - presenting your memories
            randomly. This simple, yet unique feature offers a delightful and
            unexpected journey through your photo history.{" "}
          </Typography>
          <FAQ />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

function FAQ() {
  return (
    <Box>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          How does the random selection work
        </AccordionSummary>
        <AccordionDetails>
          Our algorithm selects photos randomly from your Google Photos library.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Is my data safe?
        </AccordionSummary>
        <AccordionDetails>
          Absolutely. Your privacy and security are our top priorities.{" "}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
