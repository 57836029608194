import React, { createContext, useContext, ReactNode } from "react";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from "axios";
import { useAuth } from "./AuthProvider"; // Import useAuth from AuthProvider
import { hasJwtExpired } from "../utils/jwtUtils"; // Import hasJwtExpired

const AxiosContext = createContext<AxiosInstance>(axios.create());

interface AxiosProviderProps {
  children: ReactNode;
}

export const AxiosProvider: React.FC<AxiosProviderProps> = ({ children }) => {
  const { token, logout } = useAuth(); // Get token and logout from AuthProvider

  // Configure Axios instance
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
  });

  // Set up Axios Interceptors
  axiosInstance.interceptors.request.use(
    (config) => {
      if (token && hasJwtExpired(token)) {
        alert("Session expired. Please log in again.");
        logout();
        return Promise.reject(new AxiosError("Token expired"));
      }

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
  );

  return (
    <AxiosContext.Provider value={axiosInstance}>
      {children}
    </AxiosContext.Provider>
  );
};

export const useAxios = () => useContext(AxiosContext);
