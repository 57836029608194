import React from "react";

import { useAuth } from "./context/AuthProvider";
import { useUser } from "./context/UserProvider";
import SignIn from "./SignIn";
import Home from "./component/Home";

const AuthGate: React.FC = () => {
  const { isLoggedIn } = useAuth();
  const { user } = useUser();

  if (!isLoggedIn || !user) {
    return <SignIn />;
  }

  return <Home />;
};

export default AuthGate;
