import { jwtDecode as decode } from "jwt-decode";

export function getJwtExpiry(token: string): Date | null {
  try {
    const decoded = decode(token);
    if (!decoded || typeof decoded === "string") {
      return null;
    }

    const expiry = decoded.exp;
    if (!expiry) {
      return null;
    }

    return new Date(expiry * 1000); // Convert UNIX timestamp to JavaScript Date
  } catch (error) {
    console.error("Error decoding JWT:", error);
    return null;
  }
}

export function hasJwtExpired(token: string): boolean {
  const expiry = getJwtExpiry(token);
  if (!expiry) return true; // If there's no expiry date, assume expired

  return new Date() > expiry; // Compare current date-time to expiry date-time
}
