import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import Slider, { Settings } from "react-slick";
import { useAuth } from "../context/AuthProvider";
import { useAxios } from "../context/AxiosProvider";
import Loader from "./Loader";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";

export interface MediaList {
  id: string;
  productUrl: string;
  baseUrl: string;
  mimeType: string;
  mediaMetadata: MediaMetadata;
  filename: string;
}

export interface MediaMetadata {
  creationTime: string;
  width: string;
  height: string;
  photo?: Object;
  video?: Object;
}

export default function Home() {
  const [mediaList, setMediaList] = useState<MediaList[]>([]);
  const [activeSlide, setActiveSlide] = useState(0);
  const [fetchingPics, setFetchingPics] = useState(false);
  const slideContainerRef = useRef<HTMLDivElement>(null);
  const slideElementRef = useRef<Slider>(null);
  const menuBtnRef = useRef<HTMLDivElement>(null);

  const activeMedialElem = mediaList[activeSlide];

  const axios = useAxios();

  const { logout } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let touchStartX = 0;
    let touchStartY = 0;
    const threshold = 30; // Define what distance is considered a swipe

    const element = slideContainerRef.current;

    const handleTouchStart = (event: TouchEvent) => {
      touchStartX = event.touches[0].clientX;
      touchStartY = event.touches[0].clientY;
    };

    const handleTouchMove = (event: TouchEvent) => {
      // Prevent default to avoid scrolling during swipe
      event.preventDefault();
    };

    const handleTouchEnd = (event: TouchEvent) => {
      const touchEndX = event.changedTouches[0].clientX;
      const touchEndY = event.changedTouches[0].clientY;

      // Calculate distance on touch end to determine if it was a swipe
      const distanceX = touchEndX - touchStartX;
      const distanceY = touchEndY - touchStartY;

      if (Math.abs(distanceX) > threshold || Math.abs(distanceY) > threshold) {
        // console.log('Swipe detected.');
        // Handle swipe
      } else {
        // Calculate the middle of the element
        const rect = slideContainerRef.current!.getBoundingClientRect();
        const elementMiddle = rect.left + rect.width / 2;

        // Get the position of the click or touch
        const clientX = event.changedTouches[0].clientX;

        // Determine which side of the element was clicked
        if (clientX < elementMiddle) {
          slideElementRef.current?.slickPrev();
        } else {
          // console.log("Clicked on the right side");
          slideElementRef.current?.slickNext();
        }
      }
    };

    if (element) {
      element.addEventListener("touchstart", handleTouchStart);
      element.addEventListener("touchmove", handleTouchMove);
      element.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      if (element) {
        element.removeEventListener("touchstart", handleTouchStart);
        element.removeEventListener("touchmove", handleTouchMove);
        element.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, []);

  useEffect(() => {
    let mouseStartX = 0;
    let mouseStartY = 0;
    let isDragging = false;
    const threshold = 30; // Define what distance is considered a swipe

    const element = slideContainerRef.current;

    const handleMouseDown = (event: MouseEvent) => {
      mouseStartX = event.clientX;
      mouseStartY = event.clientY;
      isDragging = true;
    };

    const handleMouseMove = (event: MouseEvent) => {
      if (isDragging) {
        // Optional: You can handle mouse move here if needed
      }
    };

    const handleMouseUp = (event: MouseEvent) => {
      if (isDragging) {
        const mouseEndX = event.clientX;
        const mouseEndY = event.clientY;
        isDragging = false;

        // Calculate distance on mouse up to determine if it was a swipe
        const distanceX = mouseEndX - mouseStartX;
        const distanceY = mouseEndY - mouseStartY;

        if (
          Math.abs(distanceX) > threshold ||
          Math.abs(distanceY) > threshold
        ) {
          // console.log('Swipe detected.');
          // Handle swipe
        } else {
          // Calculate the middle of the element
          const rect = slideContainerRef.current!.getBoundingClientRect();
          const elementMiddle = rect.left + rect.width / 2;

          // Determine which side of the element was clicked
          if (event.clientX < elementMiddle) {
            slideElementRef.current?.slickPrev();
          } else {
            slideElementRef.current?.slickNext();
          }
        }
      }
    };

    if (element) {
      element.addEventListener("mousedown", handleMouseDown);
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      if (element) {
        element.removeEventListener("mousedown", handleMouseDown);
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      }
    };
  }, []);

  useEffect(() => {
    function handleKeyPress(e: KeyboardEvent) {
      if (e.key === "ArrowLeft") {
        slideElementRef.current?.slickPrev();
      } else if (e.key === "ArrowRight") {
        slideElementRef.current?.slickNext();
      }
    }

    window.addEventListener("keypress", handleKeyPress);
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  const fetchPics = useCallback(
    function fetchPics() {
      setFetchingPics(true);
      axios
        .get("/photos/list")
        .then((res) => {
          setMediaList((list) => [...list, ...res.data]);
        })
        .catch((err) => {
          const { response } = err;
          console.log(err);
          if (response && response.data) {
            if (response.data.code === "GOOGLE_REFRESH_TOKEN_UNAVAILABLE") {
              alert(
                "Refresh token error. Page will reload and you will need to sign in again"
              );
              logout();
              return;
            }
          } else {
          }
          alert("Something unexpected happened. Please contact support");
        })
        .finally(() => setFetchingPics(false));
    },
    [axios, logout]
  );

  useEffect(() => {
    fetchPics();
  }, [fetchPics]);

  var settings = {
    dots: false,
    infinite: false,
    speed: 250,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (index, newIndex) => {
      setActiveSlide(newIndex);
      if (mediaList.length - index < 10 && !fetchingPics) {
        fetchPics();
      }
    },
  } as Settings;

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",

        // "*": {
        //   maxWidth: "100%",
        //   maxHeight: "100%",
        // },
        ".slick-slider, .slick-list, .slick-track": {
          width: "100%",
          height: "100%",
          ".slick-slide": {
            width: "100%",
            height: "100%",
            "&>*": {
              width: "100%",
              height: "100%",
            },
          },
        },
      }}
    >
      <Box
        sx={{
          backgroundImage: "url('/water.jpg')",
          filter: "blur(10px)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "absolute",
          inset: 0,
        }}
      ></Box>
      {fetchingPics && mediaList.length === 0 && <Loader></Loader>}
      {!fetchingPics && mediaList.length === 0 && (
        <Box
          sx={{
            position: "absolute",
            inset: 0,
            width: "100%",
            p: 1,
            height: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Typography textAlign={"center"}>
            Sorry, we couldnt find any pics. If this is your first login, please
            give us upto a minute to fetch the data from Google and then reload.
          </Typography>
        </Box>
      )}

      <Box
        ref={slideContainerRef}
        sx={{ position: "relative", zIndex: 2, width: "95%", height: "95%" }}
      >
        {/* <Button onClick={fetchPics}>Get pics</Button> */}
        {mediaList.length ? (
          <Slider ref={slideElementRef} {...settings}>
            {mediaList.map((item) => {
              let view = null;
              if (item.mimeType.startsWith("image")) {
                view = (
                  <img
                    className="view"
                    // loading="lazy"
                    alt="User's google photos media"
                    key={item.id}
                    src={item.baseUrl}
                  ></img>
                );
              } else {
                view = (
                  <video
                    className="view"
                    muted
                    autoPlay
                    controls
                    loop
                    src={item.baseUrl + "=dv"}
                  ></video>
                );
              }
              return (
                <Box
                  sx={{
                    display: "inline-flex !important",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    borderRadius: "20px",
                    ".view": {
                      objectFit: "contain",
                      borderRadius: "20px",
                      // width: "100px",
                      // height: "100px",
                      maxWidth: "90%",
                      margin: "auto",
                      maxHeight: "90%",
                      ...(parseInt(item.mediaMetadata.width) /
                        window.innerWidth >
                      parseInt(item.mediaMetadata.height) / window.innerHeight
                        ? { width: "95%" }
                        : { height: "95%" }),
                      boxShadow: "0 0 20px black",
                      // background: "yellow",
                      display: "block !important",
                    },
                  }}
                  key={item.id}
                >
                  {view}
                </Box>
              );
            })}
          </Slider>
        ) : (
          <></>
        )}
      </Box>
      <Box
        sx={{
          zIndex: "99",
          position: "absolute",
          top: 10,
          padding: 1,
          right: 10,
        }}
        ref={menuBtnRef}
      >
        <IconButton onClick={handleMenuClick}>
          <MenuIcon />
        </IconButton>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleMenuClick}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <Stack
            sx={{
              borderRadius: "10px",
              alignItems: "start",
              background: "white",
              p: 1,
            }}
            direction={"column"}
            onClick={handleMenuClose}
          >
            {activeMedialElem && (
              <Link target="_blank" href={activeMedialElem.productUrl}>
                Open in Photos
              </Link>
            )}
            <Link href="https://www.yogeshbhatt.com/#contact" target="_blank">
              Support
            </Link>
            <Divider flexItem sx={{ mt: 1 }}></Divider>
            <Button onClick={logout}>Log out</Button>
          </Stack>
        </Menu>
      </Box>
    </Box>
  );
}
