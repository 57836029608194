import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { hasJwtExpired } from "../utils/jwtUtils";

interface AuthContextType {
  isLoggedIn: boolean;
  token: string | null;
  login: (token: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const initialToken = localStorage.getItem("auth-token");
  const [isLoggedIn, setIsLoggedIn] = useState(
    !initialToken ? false : (!hasJwtExpired(initialToken) as boolean)
  );
  const [token, setToken] = useState<string | null>(initialToken);

  const login = (newToken: string) => {
    setIsLoggedIn(true);
    localStorage.setItem("auth-token", newToken);
    const url = new URL(window.location.href);

    url.searchParams.delete("token");
    window.history.pushState({}, "", url);
    setToken(newToken);
  };

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("auth-token");

    setToken(null);
  };

  useEffect(() => {
    /** #TODO: user non url way of storing token
     
    */

    // Function to extract the token from the URL
    const extractTokenFromUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("token");
    };

    // Check for token in URL
    const urlToken = extractTokenFromUrl();
    if (urlToken) {
      login(urlToken);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
