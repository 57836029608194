import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loader() {
  return (
    <Box
      sx={{
        background: "rgba(255,255,255, 0.4)",
        display: "grid",
        placeItems: "center",
        width: "100%",
        height: "100%",
        position: "absolute",
        borderRadius: "5px",
        inset: 0,
        zIndex: "5",
      }}
      component="div"
    >
      <CircularProgress />
    </Box>
  );
}
